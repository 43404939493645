import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Flex, Text, Button, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import { DiscordIcon, RedditIcon } from "../components/Icon"

import commonersImage from "../images/spots/commoners.png"

const SocialPage = () => (
  <Layout>
    <SEO title="Social" />

    <Breadcrumbs title="Social" headline="Welcome to the party" />

    <Grid variant="textWrap">
      <Box>
        <Text as="p" variant="bodyLarge">We’re so glad you’re here. Whether you’re an experienced storyteller or someone who’s curious about the game, you’re welcome to join our online spaces and meet other friendly and welcoming players.</Text>
        <Text as="p" variant="bodyLarge" sx={{ mb: 4 }}>Before joining any of our social spaces, please read and understand our <Link to="/social-guidelines"><Text variant="link">Social Guidelines</Text></Link>. We ask that everyone who plays Quest and participates in our social spaces observe these guidelines.</Text>
        
        <Flex>
          <a href="https://discord.gg/UnhhyUB" target="_blank" rel="noreferrer">
            <Button variant="tertiary" sx={{ mr: 3 }}>
              <Flex sx={{ py: 1, alignItems: "center" }}>
                <Box sx={{ width: 40, mr: 2 }}>
                  <DiscordIcon fill="text" />
                </Box>
                Discord
              </Flex>
            </Button>
          </a>
        
          <a href="https://www.reddit.com/r/questgame/" target="_blank" rel="noreferrer">
            <Button variant="tertiary">
              <Flex sx={{ py: 1, alignItems: "center" }}>
                <Box sx={{ width: 40, mr: 2 }}>
                  <RedditIcon fill="text" />
                </Box>
                Reddit
              </Flex>
            </Button>
          </a>
        </Flex>
      </Box>
    </Grid>

    <Grid variant="base" sx={{ mt: [3, 4] }}>
      <Image src={commonersImage} sx={{ mx: "auto" }} />
    </Grid>
  </Layout>
)

export default SocialPage